<template>
  <div>
    <div class="min-table-h">
      <div>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="账号格式">
            <p>
              账号#密码#因梦而在#1,2,3,4,5,6;
              <span style="color: red; margin-left: 10px"
                >运行因梦而在的1-6的角色</span
              >
            </p>
          </el-form-item>
          <el-form-item label="输入账号">
            <el-input
              v-model="deviceData.user"
              type="textarea"
              placeholder="账号,密码#因梦而在#1,2,3,4,5,6;"
            ></el-input>
          </el-form-item>
          <el-form-item size="large">
            <el-button type="primary" @click="onSubmit">保存</el-button>
            <el-popconfirm
              title="是否确定要删除?"
              @confirm="onDelete"
              @confirmButtonText="确定"
              @cancelButtonText="取消"
            >
              <template #reference>
                <el-button type="danger">删除该设备所以信息</el-button>
              </template>
            </el-popconfirm>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData" show-summary :summary-method="getSummaries">
        <el-table-column prop="userServer" label="服务器" width="100"></el-table-column>
        <el-table-column prop="userName" label="角色名"></el-table-column>
        <el-table-column prop="userGrade" label="等级"></el-table-column>
        <el-table-column prop="userType" label="造型"></el-table-column>
        <el-table-column prop="userId" label="角色ID"></el-table-column>
        <el-table-column prop="userGold" label="金币"></el-table-column>
        <el-table-column prop="userGold1" label="冻结金币"></el-table-column>
        <el-table-column prop="silverCoin" label="银币"></el-table-column>
        <el-table-column prop="userSect" label="门派"></el-table-column>
        <el-table-column prop="userGold2" label="神兜兜" width="80"></el-table-column>
        <el-table-column prop="userGold3" label="宝石锤" width="80"></el-table-column>
        <el-table-column prop="huoYue" label="活跃度" width="80"></el-table-column>
        <el-table-column prop="userGl" label="隔离状态" width="80"></el-table-column>
        <el-table-column prop="runTime" label="运行时间(分)" width="110"></el-table-column>
        <el-table-column prop="userUpdateTime" label="更新时间" width="160"></el-table-column>
        <el-table-column fixed="right" label="操作" width="220">
          <template #default="scope">
            <el-button round @click="deleteid(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage1"
      :page-sizes="[10, 50, 100, 150]"
      :page-size="criteria.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  getDevice,
  getDeviceType,
  updateDeviceData,
  deleteScript,
  deleteid
} from "@/api/script";
export default {
  data() {
    return {
      query: this.$route.query,
      tableData: [],
      criteria: {
        pageNo: 1,
        pageSize: 10,
      },
      total: "",
      item: "",
      deviceData: "",
    };
  },
  computed: {
    formdata() {
      return function (time) {
        return dayjs(time).format("YYYY-MM-DD");
      };
    },
  },
  watch: {
    $route(to) {
      this.query = to.query;
      if(to.name == 'scriptshow'){
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      getDevice({
        id: this.$route.query.id,
      }).then((res) => {
        console.log(res)
        if (res.status == 200) {
          this.deviceData = res.data[0];
          getDeviceType({
            user: this.$store.state.loginName.username,
            accountType: this.deviceData.accountType,
          }).then((res) => {
            if (res.status === 200) {
              res.data.map(item=>{
                item.userUpdateTime = this.formatTime(item.userUpdateTime)
              })
              this.tableData = res.data;
            }
          });
        }
      });
    },
      // 定义格式化函数
   formatTime(time) {
    const timer = new Date(time)
    const year = timer.getFullYear()
    const mouth = timer.getMonth() + 1 >= 10 ? timer.getMonth() + 1 : '0' + (timer.getMonth() + 1)
    const date = timer.getDate() >= 10 ? timer.getDate() : '0' + timer.getDate()
    const hours = timer.getHours() >= 10 ? timer.getHours() : '0' + timer.getHours()
    const min = timer.getMinutes() >= 10 ? timer.getMinutes() : '0' + timer.getMinutes()
    const secon = timer.getSeconds() >= 10 ? timer.getSeconds() : '0' + timer.getSeconds()
    return year + '-' + mouth + '-' + date + ' ' + hours + ':' + min + ':' + secon
  },
    onSubmit() {
      updateDeviceData({
        id: this.$route.query.id,
        user: this.deviceData.user === "" ? "null" : this.deviceData.user,
      }).then((res) => {
        if (res.status == 200) {
          this.$message.success({
            message: "保存成功！",
            type: "success",
          });
        }
      });
    },
    onDelete() {
      deleteScript({
        user: this.$store.state.loginName.username,
        accountType: this.deviceData.accountType,
        id: this.$route.query.id,
      }).then((res) => {
        if (res.status === 200) {
          console.log(res);
          this.$store.commit("deleteType", this.$route.query.id);
        }
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index !== 0 && column.property !== "userGold") return;
        if (index === 0) {
          sums[index] = "金币总数";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += "";
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
    deleteid(item){
      deleteid({
        id:item.id
      }).then((res) => {
        if(res.status === 200){
          this.tableData = this.tableData.filter(e=>e.id !== item.id)
        }
      });
    }
  },
};
</script>

<style>
</style>